// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { css } from 'glamor'
import { graphql } from 'gatsby'

// import map from "lodash/map";
// import isUndefined from "lodash/isUndefined";

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Img from 'gatsby-image'

import OutLink from '@bodhi-project/components/lib/OutLink'

import Row from 'antd/lib/row'
import '@bodhi-project/antrd/lib/se-concern-lwes-type/3.10.0/row/style/css'

import Col from 'antd/lib/col'
import '@bodhi-project/antrd/lib/se-concern-lwes-type/3.10.0/col/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import StandardPage from '../components/StandardPage'
import Video from '../components/Video'
import seoHelper from '../methods/seoHelper'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const pageData = {
  pageTitle: 'About Us',
  nakedPageSlug: 'about-us',
  pageAbstract:
    'Cambridge Innovative School, originally known as Cambridge International School for Girls, is the flagship school of Learning Wings Education Systems. It was started in the year 2005, with a lofty dream of empowering young girls to be the leaders in all capacities and shape this world to be a better place. Girls are equipped to create a better future with all vitality, energy and purpose. And as we reflect, we feel proud and content with their success and most importantly their ambition to play their part in the world and make it good for all.',
}

const seoData = seoHelper(pageData)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    image1: file(relativePath: { eq: "srim.jpg" }) {
      ...defaultImage
    }
  }
`

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Styles
// ----------------------------------------------------------------------------
const pageStyles = css({
  '& strong': {
    fontWeight: 700,
    color: '#004195',
  },

  '& h1, h2, h3': {
    color: '#004195',
  },

  '& h1': {
    textTransform: 'uppercase',
    fontStyle: 'italic',
    borderTop: '4px solid #004394',
  },

  '& h3': {
    borderTop: '1.5px solid #004394',
  },
})
const pageStyle = pageStyles.toString()

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = props => {
  return (
    <StandardPage className={pageStyle} seoData={seoData}>
      <h1 className="mask-h3">About Us</h1>
      <Row gutter={{ xs: 24, sm: 24, md: 24 }}>
        <Col sm={24} md={16}>
          <p>
            Cambridge International School for Girls started its journey in the year 2005 
            with the dream of empowering young girls to be future leaders. Over the years, 
            girls have accepted the challenges,becoming inquirers and redefined the conventions and prejudices 
            to be pioneers. With the school motto 
            <i style={{ fontSize: '150%', color: '#004195' }}> "Dream Dare Do" </i> 
              we continue our journey as  
              <i> <span style={{fontWeight: 'bold'}}> Cambridge Innovative School</span> </i>
              now for girls & boys.
              
              <br/>
              
              Our aim continues to foster and fiercely protect the bright spontaneity in each child.
            Our commitment is to make learning a joyous journey and not a burden.Children are encouraged to develop
            their instrument of knowledge by using all possible sources from modern technology to traditional methods
            and gain essential skills to be successful in whatever they choose to be. We retain our old commitment of nurturing
            empowered leaders but are inspired to gracefully include the young boys in our fold. We are glad to share that the school is up to Grade 12.
            
              <br/>
              The new age school includes an international perspective with <i> <span style={{fontWeight: 'bold'}}> STEAM integrated curriculum, innovative classrooms, Artificial Intelligence based learning  </span> </i>
              with a much wider choice of fields for study. 
          </p>
          
          <p>
            <i style={{ fontSize: '150%', color: '#004195' }}>
              "Forget the theories and go to the core" – Sri M, Chief Patron
              LWES.
            </i>
          </p>
          <p>
            These guiding words by our Chief Patron, gave directions for us to
            develop education inclusive of knowledge, skill, compassion and
            spirit.
          </p>
          <br/>
          <Video
            url="https://www.youtube.com/watch?v=E9FlGtT61eA"
            className="mask-p"
          />
          <h2 className="mask-h4">Mission & Vision</h2>
          <p>
            The mission of Learning Wings Education Systems is to nurture the
            development of responsible, thoughtful citizens for life in an
            increasingly interdependent global society by creating environments
            in which students are challenged to explore, to create and to make
            decisions.
          </p>
          <p>
            Mission of LWES is manifested in kids through continuous practices
            of learning, where they are nurtured to be free of imposed
            prejudices, access opportunities to lead, participate beyond
            stereotypes, take challenges and compete to succeed.
          </p>
          <p>
            <i style={{ fontSize: '150%', color: '#004195' }}>
              Our Motto – Dream. Dare. Do
            </i>
          </p>
          <p>
            Motto is fulfilled by providing high quality academic and
            co-scholastic learning for each child to explore and dream for a
            better world and achieve beyond imagination.
          </p>
        </Col>
        <Col sm={24} md={8}>
          <div className="mask-p">
            <Img
              fluid={props.data.image1.childImageSharp.fluid}
              title="Sri M"
              alt="Sri M"
              className="test"
            />
          </div>
          <p style={{ textAlign: 'center' }}>
            <strong>Sri M</strong>
            <br />
            Chief Patron
          </p>
          <p>
            Born in India, Sri M is a spiritual guide, social reformer, educator
            and writer. To learn more about him and his work visit&nbsp;
            <OutLink to="https://satsang-foundation.org/">
              The Satsang Foundation website
            </OutLink>
            .
          </p>
          <p>
            Sri M says: “Go to the core. Theories are of no use.” His message
            seeks to transcend the outer-shell of all religions, by exploring
            their mystical essence and thus nurture the innate goodness in every
            human being.
          </p>
        </Col>
      </Row>
    </StandardPage>
  )
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
